import React from 'react'
import Sidebar from './../Sidebar';
import BarGraph from "./BarGraph";
import Calander from "./Calander";
import Classes from "./Classes";
import DonutGraph from "./DonutGraph";
import Tab from "./Tab"

function EarningMain() {
  const heading = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    paddingTop: 20,
  };
  return (
    <div className="row">
      <div className="col-2 col-xs-1 side">
        <Sidebar />
      </div>
      <div className="col-10 col-xs-11">
        <h4 style={heading}>My Earning</h4>
        <div className="row">
          <div className="col-md-8">
            <BarGraph />
          </div>
          <div className="col-md-4">
            <Calander />
          </div>
        </div>
        <div className="row">
        <div className="col-md-8">
          <Classes/>
        </div>
        <div className="col-md-4">
        <Tab />
        </div>
      </div>
      </div>
    </div>
  );
}

export default EarningMain