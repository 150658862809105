import React from "react";
import Image1 from "../../Assets/SubmitProgress.png";
import Image2 from "../../Assets/Frame41.png";
import Image3 from "../../Assets/Frame 42.png";
import Image4 from "../../Assets/InProgress.png";
import ProjectDrop from "./ProjectDrop";
import BatchDrop from "./BatchDrop";
import { Button } from "react-bootstrap";

function Header(props) {
  const icons = {
    height: 50,
    width: 50,
  };
  const num = {
    fontFamily: " Montserrat",
    fontStyle: "normal",
    fontWeight: 800,
    marginTop: 20,
    fontSize: 25,
  };
  const sub = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#6C757D",
    marginTop: -5,
    marginBottom: 30,
    fontSize: 20,
  };
  const cont = {
    padding: 20,
    backgroundColor: "#fff",
    margin: 10,
    borderRadius: 10,
    width: "100%",
  };
  const drop = {
    marginBottom: 30,
  };
  const text = {
    fontFamily: " Montserrat",
    fontStyle: "normal",
    fontWeight: 800,
    marginRight: 20,
    fontSize: 20,
  };

  return (
    <div style={cont}>
      
      <div className="row container-fluid d-flex flex-row text-center align-content-center">
        <div className="col-md-3 ">
          <img src={Image1} alt="im" className="img-fluid" style={icons} />
          <h1 style={num}>{props.data.submitted}</h1>
          <h5 style={sub}>Submitted</h5>
        </div>
        <div className="col-md-3 ">
          <img src={Image2} alt="im" className="img-fluid" style={icons} />
          <h1 style={num}>{props.data.approved}</h1>
          <h5 style={sub}>Reviewed</h5>
        </div>
        <div className="col-md-3 ">
          <img src={Image3} alt="im" className="img-fluid" style={icons} />
          <h1 style={num}>{props.data.rejected}</h1>
          <h5 style={sub}>Rejected</h5>
        </div>
        <div className="col-md-3 ">
          <img src={Image4} alt="im" className="img-fluid" style={icons} />
          <h1 style={num}>{props.data.pending}</h1>
          <h5 style={sub}>Pending projects</h5>
        </div>
      </div>
    </div>
  );
}

export default Header;
