import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import "./NavBar.css";
import Image from "../../Assets/logo.png"; 
import pic from "../../Assets/profile.png";

function NavBar() {
  const [click, steClick] = useState(false);

  const handleClick = () => steClick(!click);
  const logo = {
    paddingBottom: 25
  };
   const text = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 800,
    marginTop:-5
  };
  const text1 = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 800,
    // marginTop:-10
  };
  const pro ={
    padding: 0
  }
 

  return (
    <>
      <div className="navbar">
        <NavLink
          exact
          to="/"
          //  activeClassName="active"
          className="nav-logo"
        >
          <img
            src={Image}
            className="img-fluid"
            alt="logo"
            height="80"
            width="100"
            style={logo}
          />
        </NavLink>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <NavLink
            exact
            to="/notification"
            // activeClassName="active"
            className="nav-links mb-2"
            onClick={handleClick}
            style={text}
          >
            <NotificationsNoneIcon />
          </NavLink>
          <NavLink
            exact
            to=""
            className="nav-links"
            onClick={handleClick}
            style={text1}
          >
            <li className="nav-item d-flex " style={{ marginTop: -8 }}>
              <div>
                <img
                  src={sessionStorage.getItem("profile_pic")}
                  className="img-fluid mr-1"
                  height="30"
                  width="30"
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <h6>{sessionStorage.getItem("name")}</h6>
              </div>

              {/* <div className="dropdown-menu" aria-labelledby="navbarDropdown" >
                <NavLink
                  exact
                  to="/profile"
                  className="nav-links"
                  onClick={handleClick}
                  style={pro}
                >
                  <Link className="dropdown-item" href=" ">
                    <i className="fas fa-user"></i> Profile
                  </Link>
                </NavLink>
                <Link className="dropdown-item" href=" ">
                  <i className="fas fa-cog"></i> Setting
                </Link>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href=" ">
                  <i className="fas fa-sign-out-alt"></i> Logout
                </a>
              </div> */}
            </li>
          </NavLink>
        </ul>
        <div className="nav-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </div>
    </>
  );
}

export default NavBar;
