import { Tabs } from 'react-bootstrap';
import React from 'react'
import DonutGraph from './DonutGraph';
import DonutGraph1 from './DonutGraph1';

function Tab() {
    const cont={
        backgroundColor: "#fff",
    margin: 10,
    borderRadius: 10,
    padding: 10,
    height: "90%",
    }
    return (
        <div style={cont}>
            <Tabs defaultActiveKey="This Week" id="uncontrolled-tab-example" className="mb-3">
  <Tab eventKey="This Week" title="This Week">
    <DonutGraph />
  </Tab>
  <Tab eventKey="This Month" title="This Month">
  <DonutGraph1 />
  </Tab>

</Tabs>
        </div>
    )
}

export default Tab
