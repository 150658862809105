import React from 'react'
import ProjectScore from './../ProjectScore';
import Remark from "./Remark";
import DownloadIcon from '@mui/icons-material/Download';


function EditError() {
    const inner3={
        backgroundColor: "#CCDC00",  
        padding: 2,
        marginBottom: 10
      }
      const borError={
          border: "1px solid red"
      }
  return (
    <div>
        {/* <div className="row">
            <div className="col-md-4">
                <h5>Name: </h5>
            </div>
            <div className="col-md-8"></div>
        </div> */}
        {/* <tr>
        <th>Approval :</th>
        </tr>
        <tr>
        <th>Score :</th>
        </tr>
<tr>
        <td>
                <select className="form-control" id="sel1">
                  <option>Select</option>
                  <option>Approved</option>
                  <option>Rejected</option>
                </select>
              </td>
             
              
              <td>
                <ProjectScore />
              </td>
              </tr> */}
              <div className='row'>
                  <div className="col-md-3">
                      <h6>Name:</h6>
                  </div>
                  <div className="col-md-9">
                      <h6>Abhishek Pandey</h6>
                  </div>
              </div>

              <div className='row'>
                  <div className="col-md-3">
                      <h6>Project:</h6>
                  </div>
                  <div className="col-md-9 d-flex">
                  <div style={{marginRight: 10}}>
                  <h6>Build Automatic Speech Recognition System </h6>
                  </div>
                  <div style={inner3}>
                    <a href={File} target="_blank" rel="noreferrer">
                      <DownloadIcon />
                    </a>
                  </div>
                  </div>
              </div>

              <div className='row' >
                  <div className="col-md-3">
                      <h6>Approval: <span style={{color: "red"}}>*</span></h6>
                  </div>
                  <div className="col-md-9 mb-1">
                      <div>
                      <select className="form-control" id="sel1" style={borError}>
                  <option>Select</option>
                  <option>Approved</option>
                  <option>Rejected</option>
                </select>
                      </div>
                  </div>
              </div>
              <div className='row'>
                  <div className="col-md-3">
                      <h6>Score:<span style={{color: "red"}}>*</span> <br /> <p style={{fontSize: 10}}>score out of 10 </p>  </h6>
                  </div>
                  <div className="col-md-9 mb-1">
                      <div>
                      <ProjectScore style={borError}/>
                      </div>
                  </div>
              </div>
              <div className='row'>
                  <div className="col-md-3">
                      <h6>Remarks:</h6>
                  </div>
                  <div className="col-md-9 mb-1" >
                      <div>
                      <Remark />
                      </div>
                  </div>
              </div>
    </div>
  )
}

export default EditError