import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";


import Main from "./Components/HomeComponent/Main";
import ProjectMain from "./Components/Project/ProjectMain";
import EarningMain from "./Components/Earning/EarningMain";
import Profile from "./Components/MyProfile/Profile";
import Navbar from '../src/Components/NavBar/NavBar';
import ClassesMain from './Components/Earning/ClassesMain';
import QueriesMain from './Components/Queries/QueriesMain';
import LoginPage from './Components/Login/LoginPage';
import ProcessMain from './Components/Process/ProcessMain';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Switch>
        {/* <Route exact path="/login" component={LoginPage} /> */}
        <Route exact path="/" component={Main} />
          <Route exact path="/dashboard" component={Main} />
          <Route exact path="/projects" component={ProjectMain} />
          <Route exact path="/earnings" component={EarningMain} />
          <Route exact path="/profile" component={Profile}/>
          <Route exact path="/class" component={ClassesMain} />
          <Route exact path="/queries" component={QueriesMain} />
          <Route exact path="/processLogin" component={ProcessMain}/>
        </Switch>
      </Router>
      
      
    </div>
  );
}

export default App;
