import React from 'react'
import { Accordion } from 'react-bootstrap'
import QueriesTextArea from './QueriesTextArea'
import { Link } from 'react-router-dom';

function QueriesError() {
    const borError={
        border: "1px solid red",
    }
  return (
    <div>
        <Accordion defaultActiveKey="0">
  <Accordion.Item eventKey="0">
    <Accordion.Header>How many hours in a day do we need to dedicate daily to complete the prerequisites for the Project?</Accordion.Header>
    <Accordion.Body>
      <QueriesTextArea 
        style={borError}
        name="Ishan"
        date="12-04-22"
      />
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="1">
    <Accordion.Header>Accordion Item #2</Accordion.Header>
    <Accordion.Body>
    <QueriesTextArea />
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="2">
    <Accordion.Header>Accordion Item #2</Accordion.Header>
    <Accordion.Body>
    <QueriesTextArea />
    </Accordion.Body>
  </Accordion.Item>
  <Accordion.Item eventKey="3">
    <Accordion.Header>Accordion Item #2</Accordion.Header>
    <Accordion.Body>
    <QueriesTextArea />
    </Accordion.Body>
  </Accordion.Item>
  <div className='d-flex justify-content-end'>
        <Link>Load More....</Link>
  </div>
</Accordion>
    </div>
  )
}

export default QueriesError