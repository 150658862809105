import React from 'react'
import { Accordion } from 'react-bootstrap'
import QueriesTextArea from './QueriesTextArea'
import { Link } from 'react-router-dom';
import moment from 'moment';

function QueriesAccordion(props) {
  return (
    <div>
        <Accordion defaultActiveKey="0">
          {props.queries.map((data)=>{
            return(
              <Accordion.Item eventKey={data.queries_id}>
                <Accordion.Header>{data.question}</Accordion.Header>
                <Accordion.Body>
                  <QueriesTextArea 
                    name={data.name}
                    date={moment(data.created_at).format('DD-MM-YYYY')}
                    query_id={data.queries_id}
                    answer={data.answer}
                    is_replied={data.is_replied}
                    
                  />
                </Accordion.Body>
              </Accordion.Item>
            )
          })}
          <div className='d-flex justify-content-end'>
        <Link>Load More....</Link>
      </div>
</Accordion>
    </div>
  )
}

export default QueriesAccordion