import React from 'react'
import { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import HistoryIcon from '@mui/icons-material/History';
import EditIcon from '@mui/icons-material/Edit';
import EditContent from './EditContent';
import EditError from './EditError';
import ProjectScore from './../ProjectScore';
import Remark from "./Remark";
import DownloadIcon from '@mui/icons-material/Download';
import "../../../App.css"
import API from '../../../Routes/api';
import Swal from 'sweetalert2';
import { NativeSelect } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// function EditMain() {
//     const [show, setShow] = useState(false);
  
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
  
//     return (
//       <>
//         <div  onClick={handleShow}>
//         <EditIcon />
//         </div>
  
//         <Modal show={show} onHide={handleClose}>
//           <Modal.Header closeButton>
//             <Modal.Title>Update Status</Modal.Title>
//           </Modal.Header>
//           <Modal.Body><EditContent /> </Modal.Body>
//           <Modal.Footer>
//             <Button variant="secondary" onClick={handleClose}>
//               Close
//             </Button>
//             <Button variant="primary" onClick={handleClose}>
//               Save Changes
//             </Button>
//           </Modal.Footer>
//         </Modal>
//       </>
//     );
//   }

// function EditMain() {
  
//     const [show, setShow] = useState(false);

//       const handleClose = () => setShow(false);
//     // const handleShow = () => setShow(true);
//     const [fullscreen, setFullscreen] = useState(true);

//     function handleShow(breakpoint) {
//       setFullscreen(breakpoint);
//       setShow(true);
//     }

//   return (
//     <>
//       <div  onClick={() => handleShow()}>
//   <EditIcon />
//          </div>
//       <Modal
//       show={show} fullscreen={fullscreen} onHide={handleClose}
      
//         // show={lgShow}
//         // onHide={() => setLgShow(false)}
//         aria-labelledby="example-modal-sizes-title-lg"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="example-modal-sizes-title-lg">
//           Update Status
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//         <EditContent />
//         {/* <EditError /> */}
//          </Modal.Body>
//         <Modal.Footer>
//              <Button variant="secondary" onClick={handleClose}>
//                Close
//              </Button>
//              <Button variant="primary" onClick={handleClose}>
//         Save Changes
//              </Button>
//            </Modal.Footer>
//       </Modal>
//     </>
//   );
// }
function EditMain(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [remark, setRemark] = useState(props.remarks?props.remarks:'');
  const [score, setScore] = useState(props.score?props.score:'');
  const [projectFileStatus, setApproval] = useState(props.project_file_status?props.project_file_status:'');
  const [data,setData]=useState(props.data);
  const inner3={
    backgroundColor: "#AA66CC", 
    padding: 2,
    marginBottom: 10
  }
  
  const initiateData=()=>{
    setApproval(props.approval)
    setScore(props.score)
    setRemark(props.remark)
  }

  function handleRemarks(htmlData){
    if(htmlData.data!==''){
      //setRemark(data.data);
      var html=htmlData.data
      //console.log(html)
      setData({
        ...data,
        ['remarks']:html
      })
    }
    //console.log(remark)
    // setData({
    //   ...data,
    //   ['remarks']:JSON.stringify(data.data)
    // })
  }
  
  const handleDataChange = async(e) =>{
    setData({
      ...data,
      [e.target.name]:e.target.value,
      project_id:props.submission_id
    })
    if(e.target.name==='project_file_status'){
      setApproval(e.target.value,)
    }
    if(e.target.name==='score'){
      setScore(e.target.value,)
    }
    // if(e.target.name==='remarks'){
    //   setRemark(e.target.value,)
    // }
    //console.log(data);
  }
  // const addProjectId = () =>{
  //   setData({
  //     ...data,
  //     project_id:props.submission_id
  //   })
  // }
  
  function validateSubmit() {
    // console.log('Approval '+projectFileStatus);
    // console.log(typeof projectFileStatus);
    // console.log('score '+score)
    var overall_validation = false;
    var approval_err = true; 
    var score_err = true;
    var score_status = score.toString();
    var approval_status = projectFileStatus.toString();
    if( approval_status==='3' || approval_status ==='5'){
      document.getElementById("approval_err").innerHTML=""
      approval_err = true;
    }else{
      document.getElementById("approval_err").innerHTML="Please Select a Approval Message"
      approval_err = false;
    }
    if(score_status==='' || score_status==='0'){
      document.getElementById("score_err").innerHTML="Pelase Set the Score"
      score_err = false;
    }else{
      document.getElementById("score_err").innerHTML=""
      score_err = true;
    }
    if(score_err===true && approval_err===true){
      overall_validation = true
    }else{
      overall_validation = false;
    }
    return overall_validation;

  }

  const handleSubmit = async()=>{
    //e.preventDefault()
    //addProjectId();
    if(validateSubmit()===true){
      //console.log(data);
      API.post('projects/'+props.project_file_id,data).then(res=>{
        if(res.data.code===200){
            Swal.fire({
                title: res.data.status,
                text: res.data.message,
                icon: 'success',
                confirmButtonText: 'OK'
            }).then((result) => {
                window.location.href="/projects";
            })
        }
      });
    }
  }

  return (
    <>
    <div  onClick={() => setShow(true)}>
   <EditIcon />
          </div>
      {/* <Button variant="primary" onClick={() => setShow(true)}>
        Custom Width Modal
      </Button> */}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        // dialogClassName="modal-90w"
        id="mo"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
          Update Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div className='row'>
                  <div className="col-md-3 mb-2">
                      <h6>Name:</h6>
                  </div>
                  <div className="col-md-9 mb-2">
                      <h6>{props.name}</h6>
                  </div>
                </div>

                <div className='row'>
                  <div className="col-md-3 mb-2">
                      <h6>Project:</h6>
                  </div>
                  <div className="col-md-9 d-flex mb-2">
                    <div style={{marginRight: 10}}>
                      <h6>{props.project_title} </h6>
                    </div>
                    {/* <div style={inner3}>
                      <a href={File} style={{color: "#fff"}} target="_blank" rel="noreferrer">
                        <DownloadIcon />
                      </a>
                    </div> */}
                  </div>
                </div>

                <div className='row'>
                  <div className="col-md-3 mb-2">
                      <h6>Approval: <span style={{color: "red"}}>*</span></h6>
                  </div>
                  <div className="col-md-9 mb-2">
                    <div>
                      <NativeSelect style={props.style} name="project_file_status" id="project_file_status_input" value={projectFileStatus} onChange={handleDataChange}>
                        <option>Select</option>
                        <option  value="3">Approved</option>
                        <option  value="5">Rejected</option>
                      </NativeSelect>
                    </div>
                    <div className="text-danger" id="approval_err"></div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-md-3 mb-2">
                    <h6>Score:<span style={{color: "red"}}>*</span> <br /> <p style={{fontSize: 10}}>score out of 10 </p>  </h6>
                  </div>
                  <div className="col-md-9 mb-2">
                    <div>
                      <NativeSelect   style={props.style} name="score" value={score} onChange={handleDataChange}>
                        <option value="0">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </NativeSelect>
                    </div>
                    <div className="text-danger" id="score_err"></div>
                  </div>
              </div>
              <div className='row'>
                  <div className="col-md-3 mb-2">
                      <h6>Remarks:</h6>
                  </div>
                  <div className="col-md-9 mb-2" >
                      <div>
                      {/* <Remark /> */}
                      <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                          <div>
                          {/* <textarea className='form-control' rows={3} placeholder="Remark" name="remarks"   onChange={handleDataChange}>{remark}</textarea> */}
                            <CKEditor
                              editor={ ClassicEditor }
                              data={remark}
                              onReady={ editor => {
                                  // You can store the "editor" and use when it is needed.
                                  //console.log( 'Editor is ready to use!', editor );
                              } }
                              onChange={ ( event, editor ) => {
                                  const data = editor.getData();
                                  //console.log( { event, editor, data } );
                                  handleRemarks({ event, editor, data } );                                  
                              }}                             
                            />
                          </div>
                        </Form.Group>
                      </Form>
                      </div>
                  </div>
              </div>
       {/* <EditError /> */}
        </Modal.Body>
        <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
         Save Changes
              </Button>
            </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditMain