import React,{useState} from "react";
import { Table } from "react-bootstrap";
import DownloadIcon from "@mui/icons-material/Download";
import API from "../../Routes/api";
import moment from 'moment';

function Classes() {
  const [mentorClass, setMentorClasses]=useState([]);

  React.useEffect(() => {
    initiateData();
  }, [])

  const initiateData=async()=>{
    API.get('classes').then(res=>{
      setMentorClasses(res.data.data);
    })
  }
  const classStatus = (status) => {
    var text = '';
    switch(status){
      case 0  : text = "Join";
                break;
      case 1  : text = "Completed";
                break;
      case 2  : text = "Cancelled"; 
                break;
      default : text = "TBD";
    }
    return text;
  }
  const contain = {
    padding: 20,
    backgroundColor: "#fff",
    margin: 10,
    marginLeft: 0,
    borderRadius: 10,
    height: 600,
  };

  const table = {
    height: "95%",
  };
  return (
    <div style={contain} className="container ">
      <div className="table-responsive-sm overflow-auto scroll" style={table}>
        <Table bordered hover>
          <thead className="text-center align-content-center">
            <tr>
              <th>Batch Name</th>
              <th>Curriculum</th>
              <th>Date</th>
              <th>Time</th>
              <th>Zoom Link</th>
              <th>Total Class Taken</th>
            </tr>
          </thead>
          <tbody className="text-center align-content-center">
            {mentorClass.map((data)=>{
              return(
                  <tr>
                    <td className="text-left">
                      {data.title}
                    </td>
                    <td>
                      <DownloadIcon />
                    </td>
                    <td colSpan={3}>
                    {data.class_details?.map((val)=>{
                      return(
                      <tr style={{"border":"none"}}>
                        <td style={{"border":"none"}} width="30%">{moment(val.class_datetime).format('DD-MM-YYYY')}</td>
                        <td style={{"border":"none"}} width="30%">{moment(val.class_datetime).format('h:mm A')}</td>
                        <td style={{"border":"none"}} width="50%" className="text-left">{val.class_status === 0 ? <a href={val.class_link} target="_blank" rel="noreferrer">{classStatus(val.class_status)}</a> : classStatus(val.class_status)}</td>
                      </tr>)
                    })}
                    {data.class_details===undefined?'Details will be updated Shortly':''}
                   </td>
                    <td>{data.total_class_taken}</td>
                  </tr>
              )
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default Classes;
