import React from 'react';
import Image from "../../Assets/file.png"

function UpcomingProjects() {
    const contain ={
        backgroundColor: "#fff",
        margin: 10,
        borderRadius: 10

    };
    const picture = {
      paddingTop: 40,
    };
    const heading = {
      paddingTop: 20,
      paddingLeft: 10,
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
    };
    const sub={
        paddingBottom :40 ,
        color: " #3E7DC7"
    }
    
    return (
        <div style={contain}>
            <h3 className="d-flex justify-content-start" style={heading}>Upcoming Projects</h3>
            <img src={Image} alt="imag" className="rounded mx-auto d-block" style={picture}/>
            <h5 className="text-center" style={sub}>New Projects coming soon!</h5>
        </div>
    )
}

export default UpcomingProjects
