import React from "react";
import Agendas from "../../Extra/Agenda";
import Quotes from "../../Extra/Quotes";
import Todo from "./../../Extra/Todo";
// import Notes from './../../Extra/Notes';
import "bootstrap/dist/css/bootstrap.min.css";
import NotesModal from './../../Extra/NotesModal';


function MyNotes() {
  const contain = {
    backgroundColor: "#fff",
    margin: 10,
    borderRadius: 10,
    padding: 10,
    height: "90%",
    overflowX: "hidden",
  };
  const head = {
    padding: 10,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
  };
  const todo = {
    backgroundColor: "rgba(255, 24, 24, 0.2)",
    color: "#000",
    // marginBottom: 10,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 11,
    marginTop: 10,
    marginBottom: 20,
  };
  const agendas = {
    backgroundColor: "rgba(0, 123, 188, 0.2)",
    color: "#000",
    // marginBottom: 10,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 11,
    marginTop: 10,
    marginBottom: 20,
  };
  const quotes = {
    backgroundColor: "rgba(255, 225, 1, 0.2)",
    color: "#000",
    // marginBottom: 10,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 11,
    marginTop: 10,
    marginBottom: 10,
  };
  const add = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
  };
  const note = {
    height: "96%",
    overflowX: "hidden",
  };
  //  const btntext={
  //   fontFamily: "Montserrat",
  //   fontStyle: "normal",
  //   fontWeight: 600,
  //  }

  return (
    <div style={contain}>
      <div className="overflow-auto scroll" style={note} id="mynotes">
        <div
          className="modal"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                Create a new Note 
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={window['close']}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body"><NotesModal /></div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={window['close']}
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="d-flex justify-content-between "
            style={{ paddingTop: 20 }}
          >
            <h5 style={head}>My Notes</h5>

            <button
              type="button"
              className="btn btn-outline-primary"
              style={add}
              data-toggle="modal"
              data-target="#exampleModalCenter"
              onClick={window["notes"]}
            >
              + Add Notes
            </button>
          </div>

          <button type="button" className="btn btn-outline-danger" style={todo}>
            To-do
          </button>
          <Todo
            heading="Creating Content for the Assignment."
            date="5th sept 2021"
          />
          <Todo
            heading="Grading Assignments for AI projects"
            date="10th sept 2021"
          />
          <Todo
            heading="Creating Content for the Assignment."
            date="5th sept 2021"
          />
          <button type="button" className="btn btn-outline-primary" style={agendas}>
            Weekly agendas
          </button>
          <Agendas
            heading="Preparation for the assignments."
            date="5th sept 2021"
          />
          <Agendas
            heading="Preparation for the assignments."
            date="10th sept 2021"
          />
          <Agendas
            heading="Preparation for the assignments."
            date="5th sept 2021"
          />
          <Agendas
            heading="Preparation for the assignments."
            date="5th sept 2021"
          />
          <button type="button" className="btn btn-outline-warning" style={quotes}>
            Quotes
          </button>
          <Quotes heading="Great things Take time." />
          <Quotes heading="I am getting better at it everyday." />
          <Quotes heading="Great things Take time." />
        </div>
      </div>
    </div>
  );
}

export default MyNotes;
