import React from 'react'
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import HistoryIcon from '@mui/icons-material/History';
import EditIcon from '@mui/icons-material/Edit';
import EditContent from './EditContent';
import HistoryContent from './HistoryContent';
import { Table } from 'react-bootstrap'
import moment from 'moment';

// function HistoryMain() {
//     const [show, setShow] = useState(false);
  
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
  
//     return (
//       <>
//         <div  onClick={handleShow}>
//         <HistoryIcon />
//         </div>
  
//         <Modal show={show} onHide={handleClose}>
//           <Modal.Header closeButton>
//             <Modal.Title>Event Logs</Modal.Title>
//           </Modal.Header>
//           <Modal.Body><HistoryContent /> </Modal.Body>
//           <Modal.Footer>
//             {/* <Button variant="secondary" onClick={handleClose}>
//               Close
//             </Button>
//             <Button variant="primary" onClick={handleClose}>
//               Save Changes
//             </Button> */}
//           </Modal.Footer>
//         </Modal>
//       </>
//     );
//   }

// function HistoryMain() {

  
//     const [show, setShow] = useState(false);

//       const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
  

//   return (
//     <>

//       <div  onClick={handleShow}>
//       <HistoryIcon />
//          </div>
//       <Modal
//       show={show} onHide={handleClose}
//         size="lg"

//         aria-labelledby="example-modal-sizes-title-lg"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="example-modal-sizes-title-lg">
//           Event Logs
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body><HistoryContent /> </Modal.Body>
//       </Modal>
//     </>
//   );
// }
function EditMain(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const date ={
    width: "200px"
  }
  const namme={
    width: "200px"
  }
  const activity ={
    width: "300px"
  }
  return (
    <>
    <div  onClick={handleShow}>
       <HistoryIcon />
          </div>


      <Modal
        show={show}
        onHide={() => setShow(false)}
        // dialogClassName="modal-90w"
        id="mo"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
          Event Logs
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Table bordered hover>
          <thead>
            <tr>
              <th style={date}>Date</th>
              <th style={activity}>Activity</th>
              <th>Remarks</th>
              <th style={namme}>By</th>
            </tr>
          </thead>
          <tbody>
            {props.history?.map((data)=>{
              return(
              <tr>
                <td>{moment(data.created_at).format('DD-MM-YYYY h:mm A')}</td>
                <td>{data.activity}</td>
                <td><div dangerouslySetInnerHTML={{__html :data.remarks}}></div></td>
                <td>{data.mentor_name}</td>
              </tr>
              )
            })}
          </tbody>
        </Table> 
        </Modal.Body>
      </Modal>
    </>
  );
}


export default EditMain