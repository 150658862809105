import React,{useState} from 'react';
import { Button, Form } from 'react-bootstrap';
import API from '../../Routes/api';
import Swal from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function QueriesTextArea(props) {
  const [answers,setAnswers]                = useState([]);

  const handleAnswerChange =async(editor, data, data_query_id) => {
    //console.log(data)
    var id = data_query_id;
    let query_id=document.getElementById("query_id_"+id).value;
    setAnswers({
      ...answers,
      ['answers']: data,
      query_id:query_id
    });
    //console.log(answers)
  }

  const checkdisabled = (reply_status) => {
    if(reply_status == 0){
      return false;
    }else{
      return true;
    }
  }

  const handleAnswerSubmit = async(e) => {
    e.preventDefault()
    //console.log(answers)
    if(answers.answers===''){
      Swal.fire({
        title: "Empty Answer Warning",
        text: "You have not added the answer for the Query!",
        icon: 'error',
        confirmButtonText: 'OK'
      })
    }else{
      API.post('queries/answer',answers).then(res=>{
        if(res.data.code===200){
            Swal.fire({
                title: res.data.status,
                text: res.data.message,
                icon: 'success',
                confirmButtonText: 'OK'
            }).then((result) => {
                window.location.href="/queries";
            })
        }
      });
    }
  }
  const cont={
    margin: 10,
  }
  return <div style={cont} className='text-center'>
    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
        <div className="row m-0 mt-3">
          <div className="col-md-6 d-flex text-center justify-content-center">
            <h6 ><span style={{textDecoration: "underline"}}>Name:</span> {props.name}</h6>
          </div>
          <div className="col-md-6 d-flex text-center justify-content-center">
            <h6 ><span style={{textDecoration: "underline"}}>Date:</span> {props.date}</h6>
          </div>
        </div>
        {/* <Form.Control as="textarea" rows={3} placeholder="Type Here....." name="answers"  readOnly={checkdisabled(props.is_replied)} value={props.answer} onChange={handleAnswerChange} style={props.style} data-query_id={props.query_id} /> */}
        <CKEditor
          editor={ ClassicEditor }
          data={props.answer}
          onClick={checkdisabled(props.is_replied)}
          style={props.style} 
          
          onReady={ editor => {
              // You can store the "editor" and use when it is needed.
              //console.log( 'Editor is ready to use!', editor );
          } }
          onChange={ ( event, editor ) => {
              const data = editor.getData();
              //console.log( { event, editor, data } );
              var query_id=props.query_id
              handleAnswerChange(editor, data, query_id)
              //handleRemarks({ event, editor, data } );                                  
          }}           
          readOnly={checkdisabled(props.is_replied)}             
        />
      </Form.Group>
      <Form.Control type="hidden" id={"query_id_"+props.query_id} value={props.query_id}/>
    </Form>

    <Button variant="primary" size="lg" onClick={handleAnswerSubmit}>
      Submit
    </Button>
    
  </div>;
}

export default QueriesTextArea;
