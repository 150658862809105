import React from 'react'
import DashboardIcon from '@material-ui/icons/Dashboard';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PersonIcon from '@material-ui/icons/Person';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

export const Sidebardata = [
    {
        title:"Dashboard",
        icon: <DashboardIcon />,
        link: "/Dashboard",
    },
    {
        title:"My Projects",
        icon: <ImportContactsIcon />,
        link: "/projects",
    },
    {
        title:"My Classes",
        icon: <ImportContactsIcon />,
        link: "/class",
    },
    {
        title: "Queries",
        icon: < HelpCenterIcon / > ,
        link: "/queries",
    },
    {
        title:"Profile",
        icon: <PersonIcon />,
        link: "/profile",
    },
    {
        title:"Logout",
        icon: <PersonIcon />,
        link: "/logout",
    },
];
 
