import React from "react";
import { Button, Table } from "react-bootstrap";
import BatchDrop from "../HomeComponent/BatchDrop";
import ProjectDrop from "../HomeComponent/ProjectDrop";
import CircleIcon from "./../../Extra/CircleIcon";
import DownloadIcon from '@mui/icons-material/Download';
import EditMain from './Action/EditMain';
import HistoryMain from './Action/HistoryMain';
import File from "../../Assets/files.pdf"
import StatusDrop from "../HomeComponent/StatusDrop";
import "../../App.css"
//import BasicPagination from '../Project/BasicPagination';
//import TablePagination from './TablePagination';
import API from '../../Routes/api';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PaginationItem } from "@mui/material";
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import NotFound from "../../Assets/not-found.png";
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


function Batch() {
  const [projectsFilter,setProjectsFilter] = React.useState([]);
  const [projects,setProjects]=React.useState([]);
  const [page, setPage] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState();
  const [paginationLink, setPaginationLink] = React.useState([]);
  const [previousPage,setPreviousPage]=React.useState();
  const [presentPage,setPresentPage]=React.useState();
  const [nextPage,setNextPage]=React.useState();
  const [firstPage,setFirstPage]=React.useState();
  const [lastPage,setLastPage]=React.useState();
  const [pageToOpen,setPageToOpen]=React.useState();
  const [open, setOpen] = React.useState(false);
  const [message,setMessage]=React.useState();
  const [batches,setBatches]                  = React.useState([]);
  const mycard = {
    height: "250px",
    textAlign:"center",
    
  }
  const notFoundImage = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "10%",
    paddingTop:"30px"
  }

  const cursor = {
    cursor: "pointer"
  }
  const handleOpen = (remarkMessage) => {
    var setRemarkMessage = remarkMessage!=='' && remarkMessage!== null && remarkMessage!== undefined ? remarkMessage : 'No Remark Added Yet!' 
    //console.log(setRemarkMessage)
    setMessage(setRemarkMessage);
    setOpen(true);
  }
  const handleClose = () => {
    //setMessage('');
    setOpen(false);
  }

  
 
  React.useEffect(() => {
    initiateData();
  }, [])
  

  const initiateData=()=>{
    API.get('filter/projects').then(res=>{
      setProjectsFilter(res.data.data);
    })
    API.get('filter/batches').then(res=>{
      setBatches(res.data.data);
    })
    API.get('projects').then(res=>{
      setProjects(res.data.data);
      setPaginationLink(res.data.data.links)
      setRowsPerPage(res.data.data.per_page)
      setPresentPage(res.data.data.current_page)
      setPreviousPage(parseInt(res.data.data.current_page)-1)
      setNextPage(parseInt(res.data.data.current_page)+1)
      setFirstPage(1)
      setLastPage(res.data.data.last_page)
    })
  }
  const handleProjectsFilter = async() => {
    setProjects('');
    const project_id = document.getElementById("project_id").value;
    const project_status = document.getElementById("project_status")?.value;
    const student_name = document.getElementById("student_name")?.value;
    const student_email = document.getElementById("student_email")?.value;
    const project_submission_date = document.getElementById("project_submission_date")?.value;
    
    var batch_id = document.getElementById("batch").value;
    var project_id_search_string = project_id !== '' && project_id !== undefined ? '&project_id='+project_id : '';
    var project_status_search_string = project_status !== '' && project_status !== undefined ? '&project_status='+project_status : '';
    var batch_search_string = batch_id !== '' && batch_id !== undefined ? '&batch_id='+batch_id : '';
    var student_name_search_string = student_name !== '' && student_name !== undefined ? '&student_name='+student_name : '';
    var student_email_search_string = student_email !== '' && student_email !== undefined ? '&student_email='+student_email : '';
    var project_submission_date_search_string = project_submission_date !== '' && project_submission_date !== undefined ? '&project_submission_date='+project_submission_date : '';
    var search_string = project_id_search_string+project_status_search_string+batch_search_string+student_name_search_string+student_email_search_string+project_submission_date_search_string;
    API.get('projects?'+search_string).then(res=>{
      setProjects(res.data.data);
    })
  }
  const handleBatchFilterChange = async() => {
    var batch_id = document.getElementById("batch").value;
    var batch_search_string = batch_id !== '' && batch_id !== undefined ? '&batch_id='+batch_id : '';
    var search_string = batch_search_string;
    API.get('filter/projects?'+search_string).then(res=>{
      setProjectsFilter(res.data.data);
    })
  }

  const handleProjectsFilterReset = async() =>{
    initiateData();
    document.getElementById("project_id").selectedIndex = 0;
    document.getElementById("project_status").selectedIndex = 0;
    document.getElementById("batch").selectedIndex = 0;
    document.getElementById("student_name").value = '';
    document.getElementById("student_email").value = '';
    document.getElementById("project_submission_date").value = '';
  }

 

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    document.getElementById('recordsPerPage').value=event.target.value;
    if(event.target.value!==null && event.target.value!==undefined){
      handleProjectsPagination();
    }
  };


  const handlePaginationClick = (page_no) => {
    setPageToOpen(page_no);
    if(page_no!== null && page_no !== undefined){
      handleProjectsPagination();
    }
  }

  const handleProjectsPagination = () => {
    var project_id = document.getElementById("projectid").value;
    var project_status = document.getElementById("project_status").value;
    var project_id_search_string = project_id !== '' && project_id !== undefined ? '&project_id='+project_id : '';
    var project_status_search_string = project_status !== '' && project_status !== undefined ? '&project_status='+project_status : '';
    var project_per_page_variable_string = rowsPerPage !== '' && rowsPerPage !== undefined ? '&per_page='+rowsPerPage:'';
    var project_page_to_open_variable_string = pageToOpen !== '' && pageToOpen !== undefined ? '&page='+pageToOpen:'';
    var search_string =  project_id_search_string+project_status_search_string+project_per_page_variable_string+project_page_to_open_variable_string;
    API.get('projects?'+search_string).then(res=>{
      setProjects(res.data.data);
    })
  }
  function getStatusHTML(status){
    //console.log(status);
    var status_color    ='';
    var status_message  ='';
    switch(status){
      case 1 :  status_color="#5FFE27";
                status_message="Submitted";
                break;
      case 2  : status_color="Yellow";
                status_message="Pending";
                break;
      case 3  : status_color="Green";
                status_message="Approved";
                break;
      case 4  : status_color="Blue";
                status_message="In Progress";
                break;
      case 5  : status_color="Red";
                status_message="Rejected";
                break;
      case 6  : status_color="Green";
                status_message="Resubmitted";
                break;
    }
    //console.log(status_color,status_message)
    return(
      <div>
        <CircleIcon styles={{ color: status_color, marginRight: 5 }} /> {status_message}
      </div>
    )
    
  }

  function truncateRemark(string, limit)
  {
    var dots = "...";
    if(string!==undefined && string.length > limit){
      string = string.substring(0,limit) + dots;
    }
    return string;
  }

  const contain = {
    padding: 25,
    backgroundColor: "#fff",
    margin: 10,
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 10,
    height: 700,
  };

  const paginationColumn = {
    float: "none",
    width: "100%",
    borderTop: "0px solid #f1f1f1", 
    background: "#ffffff!important",
    margin: 0,
    padding: 5,
  }
  const columns = {
    color: "#6C757D",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
  };
  const head = {
    backgroundColor: "rgba(62, 125, 199, 0.2)",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    justifyContent: "center",
  };
  const table = {
    height: "80%",
  };
  const inner1 = {
    backgroundColor: "#3E7DC7" ,
    color: "#fff", 
    padding: 2,
    marginRight: 5,
    
  }
  const inner2={
    backgroundColor: "#FF6600", 
    color: "#fff", 
    padding: 2,
    marginRight: 5,
    cursor: "pointer"

  }
  const inner3={
    backgroundColor: "#AA66CC", 
    color: "#fff", 
    padding: 2,
    
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div style={contain} className="container">
      <div className="row m-0 mb-3">
        {/* <div className="col-md-4">
        <BatchDrop />
        </div> */}
        <div className="col-md-3 mb-4 mt-2">
          <TextField fullWidth label="Student Name" id="student_name" variant="outlined"/>
        </div>
        <div className="col-md-3 mb-4 mt-2">
          <TextField fullWidth label="Student Email" id="student_email" variant="outlined"/>
        </div>
        <div className="col-md-3 mb-4 d-flex justify-content-center">       
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DemoContainer components={['DatePicker']} >
              <DatePicker label="Project Submission Date"  id="project_submission_date"/>
            </DemoContainer>
          </LocalizationProvider>
        </div>       
        <div className="col-md-4">
          <Box >
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Domain
              </InputLabel>
              <NativeSelect
                defaultValue={30}
                inputProps={{
                  name: 'batch_filter',
                  id: 'batch',
                }}
                onChange={handleBatchFilterChange}
              >
                <option value="0">All Assigned Batches</option>
              {
                batches.map((val)=>
                <option value={val.id}>{val.title}</option>
                )
              }
              </NativeSelect>
            </FormControl>
          </Box>  
        </div>
        <div className="col-md-4">
           {/* <BatchDrop />  */}
          <Box sx={{ minWidth: 120 ,maxWidth: 650, marginBottom: 1 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Project
                </InputLabel>
                <NativeSelect  defaultValue={30}  inputProps={{name: 'project_filter',id: 'project_id'}}>
                  <option value="0">All Assigned Projects</option>
                  {
                    projectsFilter.map((data)=>{
                      return(
                        <option value={data.id}>{data.project_title}</option>
                      )
                    })
                  }                   
                </NativeSelect>
              </FormControl>
            </Box>
        </div>
        <div className="col-md-2">
          <Box sx={{ minWidth: 120, maxWidth: 650, marginBottom: 1 }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Status
              </InputLabel>
              <NativeSelect defaultValue={30} inputProps={{ name: "project_status", id: "project_status"}}>
                <option value="0">All</option>
                <option value="1">Submitted</option>
                {/* <option vlaue="2">Pending</option> */}
                <option value="3">Approved</option>
                {/* <option value="4">In Progress</option> */}
                <option value="5">Rejected</option>
                <option value="6">Resubmitted</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </div>
        <div className="col-md-1 mt-2">
          <Button style={{backgroundColor: "#FFA000", borderColor: "#FFA000", borderRadius: 10}} onClick={handleProjectsFilter}>Find</Button>
        </div>
        <div className="col-md-1 mt-2">
          <Button style={{borderRadius: 10}} onClick={handleProjectsFilterReset}>Reset</Button>
        </div>
      </div>

      <div className="table-responsive-sm overflow-auto scroll" style={table}>
        <Table className="table table-fixed">
          <thead className="text-center align-content-center" style={head}>
            <tr>
              <th scope="col">Project Title</th>
              <th scope="col">Batch</th>
              <th scope="col">Student Name</th>
              <th scope="col">Status</th>
              {/* <th scope="col">Batch</th> */}
              <th scope="col">Remarks</th>
              <th scope="col">Score</th>
              <th scope="col">Action/View</th>
            </tr>
          </thead>
          <tbody className=" align-content-start" style={columns}>
          {
            projects.data?.length>0?
            projects.data?.map((val)=>{
              
              
              return(
            <tr>
              <td>{val.project_title}</td>
              <td>{val.internship_batch_title}</td>
              <td>{val.name}</td>
              <td>{getStatusHTML(val.project_file_status)}</td>
              {/* <td>AI_LANGUIFY BATCH 4 </td> */}
              {/* <td onClick={()=>handleOpen(val.remarks)}>{truncateRemark(val.remarks?val.remarks:"",20)}</td> */}
              <td onClick={()=>handleOpen(val.remarks)} dangerouslySetInnerHTML={{__html : truncateRemark(val.remarks?val.remarks:"",20)}}/>
              <td>
                {val.score}
              </td>
              <td>
                <div className="d-flex">
                  <div style={inner1}>
                    <EditMain data={val} name={val.name} project_title={val.project_title} project_file_id={val.project_file_id} project_file_status={val.project_file_status} score={val.score} remarks={val.remarks}/>
                  </div>
                  <div style={inner2}>
                    <HistoryMain history={val.project_event_logs!==undefined?val.project_event_logs:''}/>
                  </div>
                  <div style={inner3}>
                    <a href={val.project_file_link} style={{color: "#fff"}} target="_blank" rel="noreferrer">
                      <DownloadIcon />
                    </a>
                  </div>
                </div>
              </td>
            </tr>
              )
            })
            :
            <tr border="0">
              <td colSpan="7" className="text-center">
                <img style={notFoundImage} src={NotFound}/>
                <p>No Data Found!</p>
              </td>
            </tr>
            
          } 
          </tbody>
        </Table>
      </div>
      
      <div className="mt-4 ">      
        <div className="row">
          <div className="col-md-7" style={{"padding": "0px!important"}}> 
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item"><a className="page-link" href="#" onClick={() => handlePaginationClick(previousPage)}>Previous</a></li>
                {projects.links?.map((data)=>{                  
                  if(data.label !== "pagination.previous" && data.label !== "pagination.next")
                  return(
                    <li className={data.active === true?"page-item active":"page-item"}><a className="page-link" href="#" onClick={() => handlePaginationClick(data.label)}>{data.label}</a></li>
                  );                  
                })}
                <li className="page-item"><a className="page-link" href="#" onClick={() => handlePaginationClick(nextPage)}>Next</a></li>                
              </ul>
            </nav>
          </div>
       
          <div className="col-md-2" style={{"padding": "0px!important"}}> 
            <div className="row">
              <div className="col-md-8">
                <small>Records per Page</small>
              </div>
              <div className="col-md-4">
                <select class="form-control" id="recordsPerPage" name="per_page"  value={projects.per_page} onChange={handleChangeRowsPerPage}>      
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
              
          </div>
          <div className="col-md-3" style={{"padding": "0px!important"}}> 
            <div className="">
              <div id="pagination" className=""> 
                <span className="pull-left marginRight-md"></span>
                <input type="hidden" id="totalCount" value=""></input>
                <input type="hidden" id="sort_column" value=""></input>
                <input type="hidden" id="sort_order" value=""></input>
                <div className="row">
                  <div className="col-md-6 text-center">
                    <span className="pull-left pagination-count ">
                      <small>Showing</small>
                      <b>{projects.from}</b>
                      <small>to</small>
                      <b>{projects.to}</b> 
                      <small>out of</small> 
                      <b>{projects.total}</b>
                    </span>
                  </div>
                  <div className="col-md-6 text-end">
                    <div className="pull-right pagination-count ui-checkbox ui-checkbox-primary">Total<span className="totalCount"><b>{projects.total}</b></span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                View Remark
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }} dangerouslySetInnerHTML={{__html : message}}>
              </Typography>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Batch;
