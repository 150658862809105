import React, { useState } from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';

function Calander() {
  const [value, onChange] = useState(new Date());
  const cont={
    padding: 20,
    backgroundColor: "#fff",
    // margin: 10,
    height: "100%",
    borderRadius: 10,
    width: "95%",
    
  };
  const heading={
    fontFamily: "Montserrat",
fontStyle: "normal",
fontWeight: 600,
paddingTop: 10,
marginBottom: 40
  }

  return (
    <div style={cont}>
        <h4 style={heading}>My Calander</h4>
      <Calendar onChange={onChange} value={value}  />
    </div>
  );
}

export default Calander;
