import React from "react";
import { Table } from "react-bootstrap";

function Status(props) {
  const contain = {
    padding: 20,
    backgroundColor: "#fff",
    margin: 10,
    borderRadius: 10,
    height: "90%"
  };
  const columns = {
    color: "#6C757D",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
  };
  const head = {
    backgroundColor: "rgba(62, 125, 199, 0.2)",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
  };
  const heading = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
  };
  const table={
    height: "90%"
  }
  return (
    <div style={contain} className="container " >
      <h3 style={heading}>Status</h3>
      <div className="table-responsive-sm overflow-auto scroll" style={table}>
      <Table style={{marginRight: 10}} className="table">
        <thead className="text-center align-content-center" style={head}>
          <tr>
            <th>Batch</th>
            <th>Project Name</th>
            <th>Submitted Projects</th>
            <th>approved Projects</th>
            <th>Pending projects</th>
          </tr>
        </thead>
        <tbody className="text-center align-content-center" style={columns}>
          {props.data.projects?.map((val)=>{
            return(
              <tr>
                <td className="text-left">{val.batch_title}</td>
                <td className="text-left">{val.project_title}</td>
                <td>{val.project_file_submitted}</td>
                <td>{val.project_file_approved}</td>
                <td>{val.project_file_pending}</td>
              </tr>
            )
          })}
          
          
        </tbody>
      </Table>
      </div>
    </div>
  );
}

export default Status;
