import React from 'react'
import { Table } from 'react-bootstrap'

function HistoryContent() {
  const date ={
    width: "200px"
  }
  const namme={
    width: "200px"
  }
  const activity ={
    width: "300px"
  }
  return (
    <div>
      <Table bordered hover>
        <thead>
          <tr>
            <th style={date}>Date</th>
            <th style={activity}>Activity</th>
            <th>Remarks</th>
            <th style={namme}>By</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>21-04-22 12:19PM</td>
            <td>Project Approved with score</td>
            <td></td>
            <td>Mentor Name</td>

          </tr>
          <tr>
            <td>21-04-22 12:19PM</td>
            <td>Project Rejected</td>
            <td>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam,
              cupiditate expedita! Iusto nulla atque at quidem nostrum inventore
              molestias similique, tenetur sequi incidunt tempora maiores
              recusandae autem cupiditate nemo? Doloremque.
            </td>
            <td>Mentor Name</td>

          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default HistoryContent   