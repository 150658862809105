import React from 'react'
import "../App.css";
import {Sidebardata} from './Sidebardata';
import API from '../Routes/api';

function Sidebar() {
    const destroyTokenRedirect  = (e) => {
        e.preventDefault();
        //console.log('deleting token')
        API.post('logout');
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("name");
        sessionStorage.removeItem("profile_pic");
        window.location.href=process.env.REACT_APP_HOME_URL;
    }
    return (
        <div className="Sidebar">
            <ul className="SidebarList">
            {Sidebardata.map((val, key)=>
            {
                return (
                    val.title!=="Logout"?
                    <li className="row" id={window.location.pathname == val.link ? "active" : ""} key={key} onClick={()=>{window.location.pathname = val.link}} >                    
                        <div id="icon">{val.icon}</div> 
                        <div id="title">
                            {val.title}
                        </div>
                    </li>:
                    <li className="row" id={window.location.pathname == val.link ? "active" : ""} key={key} onClick={destroyTokenRedirect} >                    
                        <div id="icon">{val.icon}</div> 
                        <div id="title">
                            {val.title}
                        </div>
                    </li>
                );
            }
            )}
            </ul>
        </div>
        );
    
}

export default Sidebar
