import React from "react";
import { Doughnut } from "react-chartjs-2";

const data = {
    labels: ['Non-submitted', 'Submitted'],
  datasets: [
    {
        label: '# of Votes',
      data: [20,  3],
      backgroundColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

function DonutGraph1() {
  const wrap = {
    backgroundColor: "#fff",
    padding: 50,
    borderRadius: 10,
    margin: 10,
    marginLeft: 0
  };
  return (
    <>
      <div style={wrap}>
        <Doughnut data={data} style={{maxHeight: 290}}/>
      </div>
    </>
  );
}

export default DonutGraph1;
