import React from 'react'
import Sidebar from './../Sidebar';
import QueriesTab from './QueriesTab';

function QueriesMain() {
    const heading={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    paddingTop: 20,
    
      }
    return (
      <div className="row">
      <div className="col-2 col-lg-2 col-md-1 col-xs-1 side" >
    <Sidebar />
    </div>
    <div className="col-10 col-lg-10 col-md-11 col-xs-11 pad">
      <h4 style={heading} className="head">Queries</h4>
        <QueriesTab />
    </div>
    </div> 
      
    )
}

export default QueriesMain

