import React,{useState} from 'react';

import Header from './Header';
import MyNotes from './MyNotes';
import Status from './Status';
import UpcomingProjects from './UpcomingProjects';
import Sidebar from './../Sidebar';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { Button } from "react-bootstrap";
import API from '../../Routes/api'

function Main() {
  const cont = {
    padding: 20,
    backgroundColor: "#fff",
    margin: 10,
    borderRadius: 10,
    width: "100%",
  };

  const drop = {
    marginBottom: 30,
  };
  
  const [batches,setBatches]                  = useState([]);
  const [projects, setProject]            = useState([]);
  const [projectFilter,setProjectFilter]  = React.useState([]);


  React.useEffect(() => {
    initiateData();
  }, [])

  const initiateData=async()=>{
    API.get('filter/batches').then(res=>{
      setBatches(res.data.data);
    })
    API.get('dashboard/kpi').then(res=>{
      setProject(res.data.data);
    })
    API.get('filter/projects').then(res=>{
      setProjectFilter(res.data.data);
    })
  }


  const handleUnansweredFilter = async() => {
    var project_id = document.getElementById("projects").value;
    var batch_id = document.getElementById("batch").value;
    var project_search_string = project_id !== '' && project_id !== undefined ? '&project_id='+project_id : '';
    var batch_search_string = batch_id !== '' && batch_id !== undefined ? '&batch_id='+batch_id : '';
    var search_string = project_search_string+batch_search_string;
    API.get('dashboard/kpi?'+search_string).then(res=>{
      setProject(res.data.data);
    })
  }

  const handleFilterReset = async() =>{
    initiateData();
    document.getElementById("projects").selectedIndex = 0;
  }

  const handleBatchFilterChange = async() => {
    var batch_id = document.getElementById("batch").value;
    var batch_search_string = batch_id !== '' && batch_id !== undefined ? '&batch_id='+batch_id : '';
    var search_string = batch_search_string;
    API.get('filter/projects?'+search_string).then(res=>{
      setProjectFilter(res.data.data);
    })
  }

  const heading={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    paddingTop: 20
  }
    return (
        <div className="row">
          <div className="col-2 col-lg-2 col-md-1 col-xs-1 side" >
            <Sidebar />
          </div>
          <div className="col-10 col-lg-10 col-md-11 col-xs-11 pad">
            <h4 style={heading} className="head">Dashboard</h4>
            <div className="row m-0">
              <div className="col-md-12">
                <div style={cont}>
                  <div className="row d-flex justify-content-around" style={drop}>
                    <div className="col-md-4 form-group d-flex ">
                      <Box >
                        <FormControl fullWidth>
                          <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Domain
                          </InputLabel>
                          <NativeSelect
                            defaultValue={30}
                            inputProps={{
                              name: 'batch_filter',
                              id: 'batch',
                            }}
                            onChange={handleBatchFilterChange}
                          >
                            <option value="0">All Assigned Batches</option>
                          {
                            batches.map((val)=>
                            <option value={val.id}>{val.title}</option>
                            )
                          }
                          </NativeSelect>
                        </FormControl>
                      </Box>                    
                    </div>
                    <div className="col-md-4 form-group d-flex ">
                      <Box >
                        <FormControl fullWidth>
                          <InputLabel variant="standard" htmlFor="uncontrolled-native">
                          Project
                          </InputLabel>
                          <NativeSelect defaultValue={30} inputProps={{name: 'project_filter',id: 'projects'}}>
                            <option value="0">All Assigned Projects</option>
                            {
                              projectFilter?.map((data)=>{
                                return(
                                  <option value={data.id}>{data.project_title}</option>
                                )
                              })
                            }                   
                          </NativeSelect>
                        </FormControl>
                      </Box>                    
                    </div>
                    <div className="col-md-3 mt-2 ">
                      <Button style={{backgroundColor: "#FFA000",borderColor: "#FFA000",borderRadius: 10,marginRight:10}} onClick={handleUnansweredFilter}>
                        Find
                      </Button>
                    
                      <Button style={{ borderRadius: 10 }} onClick={handleFilterReset}>Reset</Button>
                    </div>
                  </div>
                  <Header data={projects}/>
                </div>
              </div>
              <div className="row m-0">
                <div className="col-md-12">
                  <Status data={projects}/>
                </div>
              </div>
            </div>
          </div> 
        </div>
    )
}

export default Main
