import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import QueriesAccordion from './QueriesAccordion';
import BatchDrop from '../HomeComponent/BatchDrop';
import QueriesDate from './QueriesDate';
import ResponseAcc from './Response/ResponseAcc';
import QueriesError from './QueriesError';
import ProjectDrop from '../HomeComponent/ProjectDrop';
import { Button} from "react-bootstrap";
import API from '../../Routes/api';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { TextField } from '@mui/material';
// import BasicDateRange from './BasicDateRange';
import moment from 'moment';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function QueriesTab() {
  const [value, setValue] = React.useState(0);
  const [queries,setQueries]                = React.useState([]);
  const [answeredQueries,setAnsweredQueries]                = React.useState([]);
  const [startdate,setStartDate] = React.useState([]);
  const [enddate,setEndDate] =React.useState([]); 
  const [startdatetwo,setStartDateTwo] = React.useState([]);
  const [enddatetwo,setEndDateTwo] =React.useState([]); 
  const [searchdate,setSearchDate] =React.useState([]); 
  const [searchdatetwo,setSearchDateTwo] = React.useState([]);
  const [batches,setBatches] = React.useState([]);
  const [projects,setProjects] = React.useState([]);
  
  React.useEffect(() => {
      initiateData();
      setSearchDate(" ")
  }, [])

  const initiateData=async()=>{
      API.get('queries?course_id=0&answered=0').then(res=>{
        setQueries(res.data.data);
      })
      API.get('filter/projects').then(res=>{
        setProjects(res.data.data);
      })
  }

  const getAnsweredData=async()=>{
    API.get('queries?course_id=0&answered=1').then(res=>{
      setAnsweredQueries(res.data.data);
    })
}

  const handleUnansweredFilter = async() => {
    var project_id = document.getElementById("unanswered_project").value;
    var project_search_string = project_id !== '' && project_id !== undefined ? '&project='+project_id : '';
    var date_search_string = searchdate !== '' && searchdate !== undefined ? '&date='+searchdate : '';
    var search_string = project_search_string+date_search_string;
    API.get('queries?course_id=0'+search_string).then(res=>{
      setQueries(res.data.data);
    })
  }

  const handleAnsweredFilter =async() =>{
    var project_id = document.getElementById("answered_project").value;
    var project_search_string = project_id !== '' && project_id !== undefined ? '&project='+project_id : '';
    var date_search_string = searchdatetwo !== '' && searchdatetwo !== undefined ? '&date='+searchdatetwo : '';
    var search_string = project_search_string+date_search_string;
    API.get('queries?course_id=0&answered=1'+search_string).then(res=>{
      setAnsweredQueries(res.data.data);
    })
  }

  const handleUnansweredFilterReset = async() =>{
    initiateData();
    setSearchDate(" ")
    document.getElementById("unanswered_project").selectedIndex = 0;
  }

  const handleAnsweredFilterReset = async() =>{
    getAnsweredData();
    setSearchDateTwo(" ")
    document.getElementById("answered_project").selectedIndex = 0;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDateChangeEvent = (event,picker) => {
    setStartDate(picker.startDate.format("DD/MM/YYYY"));
    setEndDate(picker.endDate.format("DD/MM/YYYY"));
    setSearchDate(startdate+" - "+enddate)
  }

  const handleAnsweredDateChangeEvent=(event,picker)=>{
    setStartDateTwo(picker.startDate.format("DD/MM/YYYY"));
    setEndDateTwo(picker.endDate.format("DD/MM/YYYY"));
    setSearchDateTwo(startdatetwo+" - "+enddatetwo)
  }

  return (
    <Box sx={{ width: '100%' , padding: 1}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Unanswered" {...a11yProps(0)} onClick={initiateData}/>
          <Tab label="Responded" {...a11yProps(1)} onClick={getAnsweredData}/>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} >
      <div className="row mb-2">
         {/* <div className="col-md-3">
            <Box sx={{ minWidth: 120 ,maxWidth: 650, marginBottom: 1 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Batch
                </InputLabel>
                <NativeSelect
                  defaultValue={30}
                  inputProps={{
                    name: 'mentor',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value="0">All Assigned Batches</option>
                {
                  BatchData.map((val)=>
                  <option value={val.num}>{val.batch}</option>
                  )
                } 
                  
                </NativeSelect>
              </FormControl>
            </Box>
          </div> */}
          <div className="col-md-4">
            <Box sx={{ minWidth: 120 ,maxWidth: 650, marginBottom: 1 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Project
                </InputLabel>
                <NativeSelect
                  defaultValue={30}
                  
                  inputProps={{
                    name: 'project_filter',
                    id: 'unanswered_project'
                  }}
                >
                  <option value="0">All Assigned Projects</option>
                  {
                    projects.map((data)=>{
                      return(
                        <option value={data.id}>{data.project_title}</option>
                      )
                    })
                  }                   
                </NativeSelect>
              </FormControl>
            </Box>
          </div>
           
          <div className="col-md-3">
            <Box sx={{ minWidth: 120 ,maxWidth: 650, marginBottom: 1 }}>
              <FormControl fullWidth>
                <DateRangePicker  onEvent={handleDateChangeEvent} onApply={handleDateChangeEvent } >
                  <TextField id="standard-basic" label="Queries Created Between" variant="standard" value={searchdate}  placeholder="Choose a Date Range"/>
                </DateRangePicker>
              </FormControl>
            </Box>
          </div>
          <div className="col-md-1 mt-2">
          <Button style={{backgroundColor: "#FFA000", borderColor: "#FFA000", borderRadius: 10}} onClick={handleUnansweredFilter}>Find</Button>
        </div>
        <div className="col-md-1 mt-2">
          <Button style={{borderRadius: 10}} onClick={handleUnansweredFilterReset}>Reset</Button>
        </div>

      </div>
        <QueriesAccordion queries={queries} />
        {/* <QueriesError /> */}
      </TabPanel>
      <TabPanel value={value} index={1} >
      <div className="row mb-2">
      <div className="row mb-2">
         {/* <div className="col-md-3">
            <Box sx={{ minWidth: 120 ,maxWidth: 650, marginBottom: 1 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Batch
                </InputLabel>
                <NativeSelect
                  defaultValue={30}
                  inputProps={{
                    name: 'mentor',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value="0">All Assigned Batches</option>
                {
                  BatchData.map((val)=>
                  <option value={val.num}>{val.batch}</option>
                  )
                } 
                  
                </NativeSelect>
              </FormControl>
            </Box>
          </div> */}
          <div className="col-md-4">
            <Box sx={{ minWidth: 120 ,maxWidth: 650, marginBottom: 1 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Project
                </InputLabel>
                <NativeSelect
                  defaultValue={30}
                  
                  inputProps={{
                    name: 'project_filter_1',
                    id: 'answered_project'
                  }}
                >
                  <option value="0">All Assigned Projects</option>
                  {
                    projects.map((data)=>{
                      return(
                        <option value={data.id}>{data.project_title}</option>
                      )
                    })
                  }                   
                </NativeSelect>
              </FormControl>
            </Box>
          </div>
           
          <div className="col-md-3">
            <Box sx={{ minWidth: 120 ,maxWidth: 650, marginBottom: 1 }}>
              <FormControl fullWidth>
                <DateRangePicker  onEvent={handleAnsweredDateChangeEvent} onApply={handleAnsweredDateChangeEvent } >
                  <TextField id="standard-basic" label="Queries Created Between" variant="standard" value={searchdatetwo}  placeholder="Choose a Date Range"/>
                </DateRangePicker>
              </FormControl>
            </Box>
          </div>
          <div className="col-md-1 mt-2">
          <Button style={{backgroundColor: "#FFA000", borderColor: "#FFA000", borderRadius: 10}} onClick={handleAnsweredFilter}>Find</Button>
        </div>
        <div className="col-md-1 mt-2">
          <Button style={{borderRadius: 10}} onClick={handleAnsweredFilterReset}>Reset</Button>
        </div>

      </div>

      </div>
        <ResponseAcc answeredQueries={answeredQueries}/>
      </TabPanel>
    </Box>
  );
}
