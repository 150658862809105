import React from "react";
import Sidebar from "./../Sidebar";
import Batch from "./Batch";

function ProjectMain() {
  const heading = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    paddingTop: 10,
  };
  return (
    <div className="row">
      <div className="col-2 col-xs-1 side">
        <Sidebar />
      </div>
      <div className="col-10 pad">
        <h5 style={heading} className="head">
          My Project
        </h5>
        <div className="row m-0">
          <div className="col-md-12  col-sm-12 "></div>
          <div className="row" style={{ marginRight: 12 }}>
            <div className="col-md-12">
              <Batch />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectMain;
