import React from "react";
import { Bar } from "react-chartjs-2";

const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Sep","Oct","Nov","Dec"],
  datasets: [
    {
    //   label: "# of Votes",
      data: [12, 14, 3, 5, 2, 3,10,6,3,9,19],
      backgroundColor: [
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        
      ],
      borderColor: [
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(54, 162, 235, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

function BarGraph(){
    const wrap={
        backgroundColor: "#fff",
        padding: 30,
        borderRadius: 10
    };
    return(
<>
    <div style={wrap}>
      <Bar data={data} options={options} />
    </div>
  </>
    )
} 

export default BarGraph;
