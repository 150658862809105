import React,{useState,useEffect} from 'react'
import Sidebar from '../Sidebar';
import './Profile.css';
import Image from "../../Assets/profile.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import API from '../../Routes/api'
import Swal from 'sweetalert2';
import DownloadIcon from "@mui/icons-material/Download";
import { FileDownloadOff } from "@mui/icons-material";

function Profile() {
    const [user,setUsers]               =   useState([])
    const [bank,setBank]                =   useState([])
    const [info,setInfo]                =   useState([])
    const [password_details,setPassword] =  useState([])
    const [imgData, setImgData]         =   useState(null);


    useEffect(() => {
      fetchUsers();  
    }, [])

    
    const fetchUsers=()=>{
      API.get('auth/user').then(res=>{
        setUsers(res.data.data[0]);
        setInfo(res.data.data[0].mentor_details);
        //setImgData(process.env.REACT_APP_PUBLIC_USER_URL+res.data.data[0].other_details.profile_pic);
        setImgData(res.data.data[0].mentor_details.profile_pic_link);
    })
    }      

    const handleMentorInfoChange = (e) => {
      setInfo(
        prevState => ({
            ...prevState,
            [e.target.name]:e.target.value
        })
      );
    }
    const handlePassword = (e)=>{
      setPassword(
          prevState => ({
              ...prevState,
              [e.target.name]:e.target.value
          })
      )
    }

    const validateUserInput = () =>{
      var user_name_as_per_bank = document.getElementById("user_name_as_per_bank").value;
      var user_bank_name = document.getElementById("user_bank_name").value;
      var user_bank_account_number = document.getElementById("user_bank_account_number").value;
      var user_bank_ifsc = document.getElementById("user_bank_ifsc").value;
      var user_name_as_per_bank_validation = false;
      var user_bank_name_validation = false;
      var user_bank_account_number_validation = false;
      var user_bank_ifsc_validation = false;
     
      var validation = false;


      if(user_name_as_per_bank === '' || user_name_as_per_bank === null || user_name_as_per_bank === undefined){
          document.getElementById("user_name_as_per_bank_err").innerHTML = "Name as per bank can't be Empty!"
          user_name_as_per_bank_validation = false;
      }else{
          document.getElementById("user_name_as_per_bank_err").innerHTML = ''
          user_name_as_per_bank_validation = true;
      }

      if(user_bank_name === '' || user_bank_name === null || user_bank_name === undefined){
          document.getElementById("user_bank_name_err").innerHTML = "Bank Name can't be Empty!"
          user_bank_name_validation = false;
      }else{
          document.getElementById("user_bank_name_err").innerHTML = ''
          user_bank_name_validation = true;
      }

      if(user_bank_account_number === '' || user_bank_account_number === null || user_bank_account_number === undefined){
        document.getElementById("user_bank_account_number_err").innerHTML = "Account Number can't be Empty!"
        user_bank_account_number_validation = false;
      }else{
        document.getElementById("user_bank_account_number_err").innerHTML = ''
        user_bank_account_number_validation = true;
      }

      if(user_bank_ifsc === '' || user_bank_ifsc === null || user_bank_ifsc === undefined){
        document.getElementById("user_bank_ifsc_err").innerHTML = "IFSC Code can't be Empty!"
        user_bank_ifsc_validation = false;
      }else{
        document.getElementById("user_bank_ifsc_err").innerHTML = ''
        user_bank_ifsc_validation = true;
      }


      if(user_name_as_per_bank_validation === true && user_bank_name_validation === true && user_bank_account_number_validation === true && user_bank_ifsc_validation === true ){
          validation = true;
      }else{
          validation = false;
      }
      return validation;
    }

    const handleSubmit= (e) => {
      e.preventDefault();
      if(validateUserInput()===true){
        API.put('auth/user/update',info).then(res=>{
          if(res.data.code===200){
            Swal.fire({
              title: res.data.status,
              text: res.data.message,
              icon: 'success',
              confirmButtonText: 'OK'
            }).then((result) => {
              window.location.reload();
            });
          }      
        }).catch(err=>{
          if(err.code==='ERR_BAD_REQUEST'){
            Swal.fire({
              title: err.response.data.status,
              text: err.response.data.message,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          } 
        }); 
      } 
    };

    const handlePasswordReset = (e) => {
      e.preventDefault();
      API.put('auth/user/password/update',password_details).then(res=>{
        if(res.data.code===200){
          Swal.fire({
            title: res.data.status,
            text: res.data.message,
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            window.location.reload();
          });
        }      
      }).catch(err=>{
        if(err.code==='ERR_BAD_REQUEST'){
          Swal.fire({
            title: err.response.data.status,
            text: err.response.data.message,
            icon: 'error',
            confirmButtonText: 'OK'
          })
        } 
      });  
    };

    const heading={
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        paddingTop: 20
      };
      const cont = {
        backgroundColor: "#fff",
        margin: 10,
        marginLeft: 0,
        borderRadius: 10,
        width: "100%",
      };
      const form = {
          padding:12,  
      }
    return (
      <div>
        <div className="row">
          <div className="col-2 col-xs-1 side">
            <Sidebar />
          </div>
          <div className="col-10 col-xs-11 pad">
            <h4 style={heading} className="head">
              Profile
            </h4>
            <div style={cont} id="profile">
              <form
                action=""
                method="post"
                id="profile-edit-form"
                className="standard-form profile-info"
                novalidate
                enctype="multipart/form-data"
              >
                <div className="row">
                  <div className="col-sm-auto col-md-3 " id="nav">
                    <ul
                      className="button-nav clearfix"
                      aria-label="Profile field groups"
                      role="navigation"
                    >
                      <li className="current">
                        <a href="#" onClick={window["profile"]}>
                          Edit Profile
                        </a>
                      </li>
                    </ul>
                    <ul
                      className="button-nav-n clearfix"
                      aria-label="Profile field groups"
                      role="navigation"
                    >
                      <li className="current">
                        <a href="#" onClick={window["pass"]}>
                          Change Password
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-9" style={form}>
                    <div
                      className="card card-static"
                      style={{
                        paddingLeft: "10%",
                        paddingRight: "10%",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      <img src={imgData} className="img-fluid" style={{alignSelf:'center',justifyContent:'center',padding:'10px',height:'150px',width:'150px'}} alt="logo" height='80' width='200' />
                      {/* <label
                        style={{
                          alignSelf: "center",
                          justifyContent: "center",
                        }}
                        className="profile"
                      >
                        Change Profile Photo <br />
                        <input type="file" />
                      </label> */}
                      <div className="row">
                        <h4 style={{ color: "#000" }}>Basic Info</h4>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">
                            Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputEmail4"
                            placeholder="First Name"
                            value={user.name} 
                            readOnly
                          />
                        </div>
                        {/* <div className="form-group col-md-6">
                          <label for="inputPassword4">
                            Last Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="inputPassword4"
                            placeholder="Password"
                          />
                        </div> */}
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">
                            Phone Number<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="inputEmail4"
                            placeholder="Phone number"
                            value={user.mobile} 
                            readOnly
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label for="inputPassword4">
                            Whatsapp Number
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="inputPassword4"
                            placeholder="Whatsapp"
                            value={user.whatsapp_number} 
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">
                            Email Id<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="inputEmail4"
                            placeholder="Email"
                            value={user.email}
                            readOnly
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label for="inputPassword4">LinkedIn</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputPassword4"
                            placeholder="LinkedIn"
                            value={info.linkedin_profile} 
                            readOnly
                          />
                        </div>
                      </div>
                      <h4>Bank Account Details</h4>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">
                            Name as per bank
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="user_name_as_per_bank"
                            placeholder="Name as per bank"
                            value={info.bank_account_name}
                            name="bank_account_name"
                            onChange={handleMentorInfoChange}
                          />
                          <div className="text-danger" id="user_name_as_per_bank_err"></div>
                        </div>
                        
                        <div className="form-group col-md-6">
                          <label for="inputPassword4">
                            Bank Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="user_bank_name"
                            placeholder="Bank Name"
                            value={info.bank_name} 
                            name="bank_name"
                            onChange={handleMentorInfoChange}
                          />
                          <div className="text-danger" id="user_bank_name_err"></div>
                        </div>

                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">
                            Account Number
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="user_bank_account_number"
                            placeholder="Account Number"
                            value={info.bank_account_number} 
                            name="bank_account_number"
                            onChange={handleMentorInfoChange}
                          />
                          <div className="text-danger" id="user_bank_account_number_err"></div>
                        </div>
                        <div className="form-group col-md-6">
                          <label for="inputPassword4">
                            IFSC Code<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="user_bank_ifsc"
                            placeholder="IFSC"
                            value={info.bank_ifsc} 
                            name="bank_ifsc"
                            onChange={handleMentorInfoChange}
                          />
                          <div className="text-danger" id="user_bank_ifsc_err"></div>
                        </div>
                      </div>
                      <h4>Additional Info</h4>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">
                            Address Proof (Aadhar/Pan Card)
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <input
                            type="file"
                            className="form-control"
                            id="inputEmail4"
                            readOnly
                          /> */}
                          <div>
                          {
                              info.address_proof_link !== undefined && info.address_proof_link !== '' && info.address_proof_link !== null  ? 
                                <a href={info.address_proof_link} target="_blank" rel="noreferrer">
                                  <DownloadIcon />
                                </a>
                              :
                                <FileDownloadOff/>
                            }
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <label for="inputEmail4">
                            Resume/CV<span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <input
                            type="file"
                            className="form-control"
                            id="inputEmail4"
                            readOnly
                          /> */}
                          <div>
                            {
                              info.resume_file_link !== undefined && info.resume_file_link !== '' && info.resume_file_link !== null  ? 
                                <a href={info.resume_file_link} target="_blank" rel="noreferrer">
                                  <DownloadIcon />
                                </a>
                              :
                                <FileDownloadOff/>
                            }
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ marginLeft: "40%", marginRight: "40%" }}
                        onClick={handleSubmit}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div style={cont} id="pass">
              <form
                action=""
                method="post"
                id="profile-edit-form"
                className="standard-form profile-info"
                novalidate
                enctype="multipart/form-data"
              >
                <div className="row">
                  <div className="col-sm-auto col-md-3 " id="nav">
                    <ul
                      className="button-nav-n clearfix"
                      aria-label="Profile field groups"
                      role="navigation"
                    >
                      <li className="current">
                        <a href="#" onClick={window["profile"]}>
                          Edit Profile
                        </a>
                      </li>
                    </ul>
                    <ul
                      className="button-nav clearfix"
                      aria-label="Profile field groups"
                      role="navigation"
                    >
                      <li className="current">
                        <a href="#" onClick={window["pass"]}>
                          Change Password
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-9" style={form}>
                    <div
                      className="card card-static"
                      style={{
                        paddingLeft: "10%",
                        paddingRight: "10%",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      <img
                        src={Image}
                        className="img-fluid"
                        style={{
                          alignSelf: "center",
                          justifyContent: "center",
                          padding: "10px",
                          height: "150px",
                          width: "130px",
                        }}
                        alt="logo"
                        height="80"
                        width="200"
                      />
                      <div className="row">
                        <h4 style={{ color: "#000" }}>Change Password</h4>
                      </div>
                      <div className="form-group">
                        <label for="inputAddress">Old Password</label>
                        <input type="password" className="form-control" name="old_password" onChange={handlePassword} />
                      </div>
                      <div className="form-group">
                        <label for="inputAddress">New Password</label>
                        <input type="password" className="form-control"   name="password" onChange={handlePassword} />
                      </div>
                      <div className="form-group">
                        <label for="inputAddress">Confirm Password</label>
                        <input type="password" className="form-control"  name="password_confirmation" onChange={handlePassword}/>
                      </div>
                      <div className="col-md-6">
                          <button type="submit" className="btn btn-primary" onClick={handlePasswordReset}>Change Password</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Profile
