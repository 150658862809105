import React from "react";
import { Accordion } from "react-bootstrap";
import { Link } from 'react-router-dom';
import moment from 'moment';

function ResponseAcc(props) {
  const highlight = {
    fontStyle: "bold",
  };
  return (
    <div>
      <Accordion defaultActiveKey="0">
      {props.answeredQueries.map((data)=>{
        return(
          <Accordion.Item eventKey={data.queries_id}>
            <Accordion.Header>{data.question}</Accordion.Header>
            <Accordion.Body>
            <div dangerouslySetInnerHTML={{__html : data.answer}}/>
              <div className="row m-0 mt-3">
                <div className="col-md-6 d-flex text-center justify-content-center">
                  <h5 style={highlight}>{data.name}</h5>
                </div>
                {/* <div className="col-md-4 d-flex text-center justify-content-center">
                  <h5 style={highlight}>{props.course}</h5>
                </div> */}
                <div className="col-md-6 d-flex text-center justify-content-center">
                  <h5 style={highlight}>{moment(data.created_at).format('DD-MM-YYYY')}</h5>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          )
        })}
        <div className='d-flex justify-content-end'>
        <Link>Load More....</Link>
      </div>
      </Accordion>
    </div>
  );
}

export default ResponseAcc;
